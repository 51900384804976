import { useMemo, useRef, useState } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';

type Options = {
	defaultPageSize?: number;
};

export function usePagination({ defaultPageSize }: Options = {}) {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(defaultPageSize ?? 5);

	const paginationModel: GridPaginationModel = {
		page,
		pageSize,
	};

	return {
		page,
		setPage,
		pageSize,
		setPageSize,
		onPageChange: (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
			setPage(newPage);
		},
		onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setPageSize(parseInt(event.target.value, 10));
			setPage(0);
		},
		paginationModel,
		onPaginationModelChange: (model: GridPaginationModel) => {
			setPage(model.page);
			setPageSize(model.pageSize);
		},
	};
}

export const usePaginationRowCount = (count: number | undefined) => {
	const rowCountRef = useRef(count || 0);

	const rowCount = useMemo(() => {
		if (count !== undefined) {
			rowCountRef.current = count;
		}
		return rowCountRef.current;
	}, [count]);

	return rowCount;
};

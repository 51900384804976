import React from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Icon } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { SimpleTable } from '@/molecules/simple-table';
import { BackofficeProcessingResultsTable } from '@/organisms/backoffice/backoffice-processing-results-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';

export const BackofficeOrganizationsInvoiceDetail = () => {
	const { id: organizationId, invoiceId } = useParams<{ id: string; invoiceId: string }>();
	if (!organizationId || !invoiceId) throw new Error('No organization or transaction id');

	const confirm = useConfirm();

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({
		id: organizationId,
	});
	const { data, isError } = trpc.backoffice.organizations.invoices.get.useQuery({
		id: invoiceId,
	});

	const { mutate: extract } = trpc.backoffice.organizations.invoices.extract.useMutation();

	return (
		<>
			<Back to={links.backoffice.organizations.detail.invoices.ROOT.replace(':id', organizationId)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Invoice of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<Typography variant="h3">Overview</Typography>

			{data && (
				<SimpleTable
					rows={[
						['ID', data.id],
						['Supplier Name', data.supplierName],
						['Invoice Date', data.invoiceDate],
						['Due Date', data.dueDate],
						[
							'Total Amount',
							<FormattedNumber
								value={centsToAmount(Number(data.totalAmount))}
								style="currency"
								currency={data.currency}
							/>,
						],
						[
							'Total Amount on Invoice',
							<FormattedNumber
								value={centsToAmount(Number(data.totalAmountOnInvoice))}
								style="currency"
								currency={data.currencyOnInvoice}
							/>,
						],
						['Item Description', data.itemDescription],
						[
							'Email Message',
							<Link
								to={links.backoffice.organizations.detail.mailbox.detail
									.replace(':id', organizationId)
									.replace(':messageId', data.emailMessageId)}
							>
								{data.emailMessageId}
							</Link>,
						],
						[
							'Email Attachment',
							<Link
								to={links.backoffice.organizations.detail.mailbox.detail
									.replace(':id', organizationId)
									.replace(':messageId', data.emailMessageId)}
							>
								{data.attachmentFilename}
							</Link>,
						],
					]}
					sx={{ maxWidth: 900 }}
				/>
			)}

			<Typography variant="h3">Processing Results</Typography>

			<IconButton
				onClick={() => {
					extract({ invoiceId });
				}}
				sx={{ fontSize: 14 }}
			>
				<Icon name="RefreshCw" />
			</IconButton>

			<BackofficeProcessingResultsTable invoiceId={invoiceId} />
		</>
	);
};

import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

enum Value {
	Undefined = 'undefined',
	True = 'true',
	False = 'false',
}

type Props = { id: string } & SelectProps;

export const useBooleanFilterSelect = ({ id, ...props }: Props) => {
	const [value, setValue] = useState(Value.Undefined);

	const labelId = `${id}-label`;

	const select = (
		<FormControl sx={{ width: 180 }}>
			<InputLabel id={labelId}>{props.label}</InputLabel>
			<Select
				labelId={labelId}
				value={value}
				onChange={(e) => setValue(e.target.value as Value)}
				{...props}
				size="small"
			>
				<MenuItem value={Value.Undefined}>---</MenuItem>
				<MenuItem value={Value.True}>Yes</MenuItem>
				<MenuItem value={Value.False}>No</MenuItem>
			</Select>
		</FormControl>
	);

	const booleanValue = value === Value.Undefined ? undefined : value === Value.True;

	return { select, value, booleanValue, setValue };
};

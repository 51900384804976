import React, { forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';
import { icons } from 'lucide-react';

import { center } from '@/utils/sx';

export type IconName = keyof typeof icons;

type Props = {
	name: IconName;
	sx?: SxProps;
	onClick?: () => void;
};

export const Icon = forwardRef<HTMLDivElement, Props>(({ name, sx, onClick }, ref) => {
	const LucideIcon = icons[name];

	return (
		<Box ref={ref} sx={{ ...center, display: 'inline-flex', ...(sx ?? {}) }} onClick={onClick}>
			<LucideIcon height="1em" />
		</Box>
	);
});

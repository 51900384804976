import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Icon } from '@/atoms/icon';
import { links } from '@/paths';

type Props = {
	organizationId: string;
};

export const BackofficeOrganizationInvoices = ({ organizationId }: Props) => {
	return (
		<Typography variant="h3">
			Invoices
			<Link to={links.backoffice.organizations.detail.invoices.ROOT.replace(':id', organizationId)}>
				<IconButton sx={{ ml: 1 }}>
					<Icon name="LogIn" />
				</IconButton>
			</Link>
		</Typography>
	);
};

import React, { ReactNode, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormCheckbox } from '@/atoms/form-checkbox';

import { BackofficeToolSelect } from './backoffice-tool-select';

type Props = {
	open: boolean;
	onClose: () => void;
	title: ReactNode;
	onSubmit: (data: Schema) => void;
	defaultValues?: Schema;
};

const schema = z.object({
	isInvoice: z.boolean(),
	isSaasPayment: z.boolean(),
	toolId: z.string().optional().nullable(),
});

type Schema = z.infer<typeof schema>;

const componentDefaultValues: Schema = {
	isInvoice: true,
	isSaasPayment: true,
};

export const BackofficeEvalGroundTruthDialog = ({ open, onClose, title, onSubmit, defaultValues }: Props) => {
	const form = useForm<Schema>({
		resolver: zodResolver(schema),
	});

	useEffect(() => {
		form.reset(defaultValues ?? componentDefaultValues);
	}, [defaultValues]);

	console.log(form.formState.errors);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<DialogContent>
						<FormCheckbox name="isInvoice" label="is invoice" />
						<FormCheckbox name="isSaasPayment" label="is SaaS" />

						<Controller
							name="toolId"
							render={({ field: { value, onChange }, fieldState }) => {
								return <BackofficeToolSelect valueId={value} onChange={onChange} />;
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>Close</Button>
						<Button type="submit">Save</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

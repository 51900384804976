import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { trpc } from '@/trpc/client';
import { eerie } from '@/utils/theme';

import { ExpenseRow, rowGridSx } from './expense-table.row';

type Props = {
	search?: string;
	from: string;
	to: string;
};

export const ExpensesTable = ({ search, from, to }: Props) => {
	const { data } = trpc.expenses.list.useQuery({ from, to });

	if (!data) return null;

	return (
		<>
			<Stack gap={1.5} sx={{ mt: 2 }}>
				<Box sx={{ ...rowGridSx, bgcolor: eerie[900], color: 'common.white', p: 2.5 }}>
					<Box />
					<Box>
						<Typography variant="subtitle1">Title</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle1">Status</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle1">Bank transaction</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle1">Invoice</Typography>
					</Box>
					<Box></Box>
				</Box>

				{data.data.map((expense) => {
					return <ExpenseRow expense={expense} key={expense.id} />;
				})}
			</Stack>
		</>
	);
};

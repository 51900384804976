import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/atoms/icon';
import { links } from '@/paths';

export const SettingsMe = () => {
	const navigate = useNavigate();

	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Personal information
			</Typography>
			<Typography variant="input">On this page, you can edit settings for Blackfish organization.</Typography>

			<Box sx={{ pt: 10 }}>
				<Button
					variant="contained"
					startIcon={<Icon name="Save" />}
					onClick={() => navigate(links.settings.ROOT)}
				>
					Save information
				</Button>
			</Box>
		</>
	);
};

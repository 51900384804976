import React from 'react';
import { Alert, Button, Divider, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate, useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Row } from '@/atoms/row';
import { links } from '@/paths';
import { BackofficeOrganizationBankAccounts } from '@/templates/backoffice/backoffice-organization-bank-accounts';
import { BackofficeOrganizationExpenses } from '@/templates/backoffice/backoffice-organization-expenses';
import { BackofficeOrganizationInvoices } from '@/templates/backoffice/backoffice-organization-invoices';
import { BackofficeOrganizationMailboxes } from '@/templates/backoffice/backoffice-organization-mailboxes';
import { BackofficeOrganizationUploads } from '@/templates/backoffice/backoffice-organization-uploads';
import { BackofficeOrganizationsMembers } from '@/templates/backoffice/backoffice-organization-users';
import { trpc } from '@/trpc/client';

export const BackofficeOrganizationsDetail = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const confirm = useConfirm();
	const navigate = useNavigate();

	const { data, isError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { mutate: dropOrganization } = trpc.backoffice.organizations.drop.useMutation({
		onSuccess() {
			navigate(links.backoffice.organizations.ROOT);
		},
	});

	const onDelete = () => {
		confirm({
			title: 'Are you sure you want to delete this organization?',
		}).then(
			() => {
				dropOrganization({ id });
			},
			() => {
				// noop
			},
		);
	};

	return (
		<>
			<Back to={links.backoffice.organizations.ROOT} />

			<Typography variant="h2" sx={{ mt: 1 }}>
				Organization{data ? `: ${data.name}` : ''}
			</Typography>

			<Row sx={{ my: 1, mb: 2 }}>
				<Button size="small" variant="contained" onClick={onDelete}>
					Delete
				</Button>
			</Row>

			{isError && <Alert severity="error">There was an error</Alert>}

			<Divider sx={{ my: 2 }} />
			<BackofficeOrganizationBankAccounts organizationId={id} />
			<Divider sx={{ my: 2 }} />

			<BackofficeOrganizationMailboxes organizationId={id} />
			<Divider sx={{ my: 2 }} />

			<BackofficeOrganizationsMembers organizationId={id} />
			<Divider sx={{ my: 2 }} />

			<BackofficeOrganizationInvoices organizationId={id} />
			<Divider sx={{ my: 2 }} />

			<BackofficeOrganizationUploads organizationId={id} />
			<Divider sx={{ my: 2 }} />

			<BackofficeOrganizationExpenses organizationId={id} />
		</>
	);
};

import React from 'react';
import { Alert, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import BackofficeUploadFile from '@/organisms/backoffice/backoffice-upload-file';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['uploads']['list']['data'][number];

export const BackofficeOrganizationsUploads = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();

	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { data, isError, isLoading } = trpc.backoffice.organizations.uploads.list.useQuery({
		organizationId: id,
		page,
		pageSize,
	});

	const { mutateAsync: triggerProcess } = trpc.backoffice.organizations.uploads.triggerProcess.useMutation();

	const onTriggerProcess = async (id: string) => {
		enqueueSnackbar('Processing enqueued...');

		triggerProcess({ uploadId: id }).catch((err) => {
			enqueueSnackbar('Failed to enqueue processing');
			console.error(err);
		});
	};

	const columns: GridColDef<Item>[] = [
		{
			field: 'filename',
			headerName: 'Filename',
			flex: 1,
		},
		{
			field: 'createdAt',
			headerName: 'Created at',
			flex: 1,
			renderCell({ value }) {
				return <FormattedDatetime value={value} />;
			},
		},
		{
			field: 'id',
			headerName: '',
			width: 160,
			renderCell({ value }) {
				return (
					<Link
						href="#"
						onClick={(e) => {
							e.preventDefault();
							onTriggerProcess(value);
						}}
					>
						Trigger process
					</Link>
				);
			},
		},
	];

	const rowCount = usePaginationRowCount(data?.pagination.count);

	return (
		<>
			<Back to={links.backoffice.organizations.detail.ROOT.replace(':id', id)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Uploads of {organization?.name ?? ''}
			</Typography>

			<BackofficeUploadFile organizationId={id} />

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<DataGrid
				rows={data?.data ?? []}
				loading={isLoading}
				columns={columns}
				density="compact"
				rowCount={rowCount}
				paginationModel={paginationModel}
				paginationMode="server"
				onPaginationModelChange={onPaginationModelChange}
			/>
		</>
	);
};

import React, { useState } from 'react';
import { Box, Button, Collapse, Divider, Paper, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FormattedNumber } from 'react-intl';

import { Row } from '@/atoms/row';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';
import { amethyst, punch } from '@/utils/theme';

type Options = {
	expenseId: string;
};

const dividerSx = { my: 1.5, borderColor: 'text.disabled', borderBottomWidth: '0.5px' };

export const useExpenseTableRowPair = ({ expenseId }: Options) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [matching, setMatching] = useState<'invoice' | 'bank-payment' | undefined>();
	const [focusedIndex, setFocusedIndex] = useState(-1);

	const handleClose = () => {
		setAnchorEl(null);
		setFocusedIndex(-1);
	};

	const open = Boolean(anchorEl);

	const { data, isLoading, isError } = trpc.expenses.pair.useQuery({ expenseId }, { enabled: open });

	return {
		open,
		onOpen(element: HTMLButtonElement, matching: 'invoice' | 'bank-payment') {
			setAnchorEl(element);
			setMatching(matching);
		},

		element: (
			<>
				<Popover
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					sx={{
						'& .MuiPopover-paper': {
							width: anchorEl?.clientWidth ?? 'auto',
							bgcolor: amethyst[100],
							mt: 1.5,
						},
					}}
				>
					{isLoading && <CircularProgress />}
					{isError && <Typography>Error loading matches</Typography>}

					<Stack sx={{ width: '100%', py: 1.75, px: 1.5 }}>
						<Typography variant="tableSmall" sx={{ color: punch['950'], mb: 1.25 }}>
							Suggested invoices
						</Typography>

						{data && matching === 'invoice' && (
							<Box>
								{[...data.manual_upload, ...data.email].map(
									({ vendor, amount, due_at, invoiced_at, currency }, index) => {
										const focused = focusedIndex === index;

										return (
											<Paper
												sx={{
													bgcolor: focused ? 'common.white' : 'rgba(255, 255, 255, 0.4)',
													border: 0,
													py: 1.25,
													px: 1.2,
													cursor: !focused ? 'pointer' : 'default',
												}}
												onClick={() => setFocusedIndex(focused ? -1 : index)}
											>
												<Row sx={{ justifyContent: 'space-between' }}>
													<Typography variant="tableLarge">{vendor}</Typography>
													<Typography variant="tableLarge">
														{due_at || invoiced_at || ''}
													</Typography>
													<Typography variant="tableLarge">
														<FormattedNumber
															value={centsToAmount(Number(amount))}
															style="currency"
															currency={currency}
														/>
													</Typography>
												</Row>

												<Collapse in={focused}>
													<Divider sx={{ my: 1.75, ...dividerSx }} />

													<Row sx={{ justifyContent: 'space-between' }}>
														<Box>
															<Typography variant="tableAdditional">Name</Typography>
															<Typography variant="tableSmall">{vendor}</Typography>
														</Box>
														<Box>
															<Typography variant="tableAdditional">Date</Typography>
															<Typography variant="tableSmall">
																{due_at || invoiced_at || ''}
															</Typography>
														</Box>
													</Row>

													<Row sx={{ mt: 3 }} gap={0.5}>
														<Button variant="contained" size="small">
															Pair invoice
														</Button>
														<Button variant="link" size="small">
															View invoice
														</Button>
													</Row>
												</Collapse>
											</Paper>
										);
									},
								)}
							</Box>
						)}

						{data && matching === 'bank-payment' && (
							<Box>
								{(data.transaction ?? []).map((item) => {
									return <Paper sx={{ bgcolor: 'common.white' }}>{JSON.stringify(item)}</Paper>;
								})}
							</Box>
						)}

						<Divider sx={{ my: 1.75, borderColor: punch['200'] }} />

						<Typography variant="tableSmall" sx={{ color: punch['950'] }}>
							You dont see correct invoice?
						</Typography>

						<Box sx={{ mt: 1.5 }}>
							<Button variant="contained" size="small">
								Upload manually
							</Button>
						</Box>
					</Stack>
				</Popover>
			</>
		),
	};
};

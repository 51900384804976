import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { links } from '@/paths';
import { OnboardingMailboxes as OnboardingMailboxesList } from '@/templates/onboarding/onboarding-mailboxes';

export const OnboardingMailboxes = () => {
	const navigate = useNavigate();

	return (
		<>
			<Back to={links.onboarding.organization} />

			<Typography variant="h1" sx={{}}>
				Connect your inboxes
			</Typography>
			<Typography variant="input">
				It&apos;s an honour to have you here. We hope you&apos;ll happy too!
			</Typography>

			<OnboardingMailboxesList />

			<Box sx={{ mt: 'auto' }}>
				<Button
					variant="contained"
					startIcon={<ArrowRight />}
					sx={{}}
					onClick={() => {
						navigate(links.onboarding.bankAccounts);
					}}
				>
					Continue to next step
				</Button>
			</Box>
		</>
	);
};

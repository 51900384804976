import React from 'react';
import { Typography } from '@mui/material';

export const SettingsCurrencies = () => {
	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Currencies
			</Typography>
		</>
	);
};

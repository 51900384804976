import React from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { ChevronDown } from 'lucide-react';

import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Row } from '@/atoms/row';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';
import { TableHead, TableRow } from '../table';

type Props = {
	data: RouterOutput['bankAccounts']['list'];
};

const columns = '1fr 160px 160px 160px 120px';

export const BankAccountsList = ({ data }: Props) => {
	return (
		<>
			<TableHead
				items={['Account name', 'Transactions', 'Last synced', 'Bank account added', '']}
				columns={columns}
				sx={{ mt: 3 }}
			/>

			{(data ?? []).map(
				({ id, name, currency, lastSyncAt, institutionName, paymentTransactionsCount, createdAt }) => {
					return (
						<TableRow columns={columns} key={id}>
							<Row gap={1}>
								<Avatar src={''} sx={{ width: 32, height: 32 }} />
								<Stack sx={{ width: '100%' }}>
									<Typography
										variant="tableLarge"
										sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
									>
										{name}
									</Typography>
									<Typography variant="tableAdditional">
										{institutionName} | {currency}
									</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableMedium" sx={{ color: 'text.primary' }}>
										{String(paymentTransactionsCount)}
									</Typography>
									<Typography variant="tableAdditional">
										{String(paymentTransactionsCount)}
									</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableSmall">
										<FormattedDatetime value={lastSyncAt} />
									</Typography>
									<Typography variant="tableAdditional" sx={{}}>
										<FormattedDatetime value={lastSyncAt} />
									</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableSmall">
										<FormattedDatetime value={createdAt} />
									</Typography>
									<Typography variant="tableAdditional" sx={{}}>
										<FormattedDatetime value={createdAt} />
									</Typography>
								</Stack>
							</Row>
							<Row sx={{ justifyContent: 'flex-end' }}>
								<Button variant="contained" endIcon={<ChevronDown />} size="small">
									Manage
								</Button>
							</Row>
						</TableRow>
					);
				},
			)}
		</>
	);
};

import React from 'react';
import { Avatar, Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { first } from 'lodash';
import { Outlet } from 'react-router-dom';

import { Icon } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { links } from '@/paths';
import { useAuth } from '@/providers/auth-provider';

const navItems = [
	{ icon: <Icon name="User" />, label: 'Users', to: links.settings.users },
	{ icon: <Icon name="Mail" />, label: 'Mailboxes', to: links.settings.mailboxes },
	{ icon: <Icon name="Banknote" />, label: 'Bank Accounts', to: links.settings.bankAccounts },
	{ icon: <Icon name="DollarSign" />, label: 'Currencies', to: links.settings.currencies },
];

export const SettingsLayout = () => {
	const { user, logout, me } = useAuth();

	return (
		<>
			<Typography variant="h2">Settings</Typography>
			<Typography variant="input">On this page, you can edit settings for Blackfish organization.</Typography>

			<Paper sx={{ mt: 4, p: 3 }}>
				<Stack direction="row" gap={2.5}>
					<Avatar src={user?.photoURL ?? ''} sx={{ width: 72, height: 72 }} />
					<Stack gap={0.25}>
						<Typography variant="h3">{user.displayName}</Typography>
						<Typography variant="h6">
							{user.email} {first(me.organizations).name}
						</Typography>
					</Stack>
					<Link to={links.settings.me} sx={{ ml: 'auto', mt: 'auto' }}>
						Edit personal information
					</Link>
				</Stack>
			</Paper>

			<Divider sx={{ mb: 5, mt: 8 }} />

			<Grid container spacing={5}>
				<Grid item xs={3}>
					<Paper sx={{ py: 3, px: 2.5 }}>
						<Typography sx={{ mb: 1 }} component="p">
							Navigation
						</Typography>

						<Stack gap={1}>
							{navItems.map(({ to, label, icon }) => {
								return (
									<Link to={to} sx={{ color: 'text.primary' }}>
										<Box
											sx={{
												'display': 'flex',
												'flexDirection': 'row',
												'alignItems': 'center',
												'& svg': { color: 'grey.400', mr: 0.5, ml: -0.5 },
											}}
											component="span"
										>
											{icon}
											{label}
										</Box>
									</Link>
								);
							})}
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={9}>
					<>
						<Outlet />
					</>
				</Grid>
			</Grid>
		</>
	);
};

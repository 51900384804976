import React, { ReactNode } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { border } from '@/utils/sx';
import { eerie } from '@/utils/theme';

type Props = {
	items: ReactNode[];
	columns: string;
	sx?: SxProps;
};

export const TableHead = ({ items, columns, sx }: Props) => {
	const rowGridSx = {
		'display': 'grid',
		'borderRadius': 3,
		'grid-template-columns': columns,
		'mb': 1.25,
		...sx,
	};

	return (
		<Box sx={{ ...rowGridSx, bgcolor: eerie[900], color: 'common.white', p: 2.5 }}>
			{items.map((item, index) => {
				return (
					<Box key={index}>
						<Typography variant="subtitle1">{item}</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

type RowProps = {
	children: ReactNode;
	columns: string;
	sx?: SxProps;
};

export const TableRow = ({ children, columns, sx = {} }: RowProps) => {
	const sxRow: SxProps = {
		...border('text.disabled'),
		bgcolor: 'transparent',
		p: 1.5,
		gap: 1,
		display: 'grid',
		gridTemplateColumns: columns,
		borderRadius: 3,
		mb: 1.25,
		...sx,
	};

	return <Box sx={sxRow}>{children}</Box>;
};

import React from 'react';
import { Typography } from '@mui/material';

import { BankAccounts } from '@/templates/bank-accounts';
import { Mailboxes } from '@/templates/mailboxes';
import { Members } from '@/templates/members';

export const SettingsMailboxes = () => {
	return (
		<>
			<Mailboxes />
		</>
	);
};

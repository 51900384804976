import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Plus } from 'lucide-react';

import { Row } from '@/atoms/row';
import { BankAccountsList } from '@/molecules/bank-accounts/bank-accounts-list';
import { ConnectBankAccount } from '@/molecules/bank-accounts/connect-bank-account';
import { trpc } from '@/trpc/client';

export const BankAccountsManagement = () => {
	const [connectVisible, setConnectVisible] = useState(false);

	const { data } = trpc.bankAccounts.list.useQuery();

	return (
		<>
			<BankAccountsList data={data} />

			{Boolean((data && data.length === 0) || (data?.length && connectVisible)) && <ConnectBankAccount />}

			<Row sx={{ mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>
				<Button variant="contained" size="small" endIcon={<Plus />} onClick={() => setConnectVisible(true)}>
					Add bank account
				</Button>

				{data && <Typography variant="body2">{data.length} bank accounts in total</Typography>}
			</Row>
		</>
	);
};

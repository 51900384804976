import React, { useState } from 'react';
import { Typography } from '@mui/material';

import { ExpensesTable } from '@/organisms/expenses-table';
import { trpc } from '@/trpc/client';
import { config } from '@/utils/config';

type Props = {
	from: string;
	to: string;
};

export const Subscriptions = ({ from, to }: Props) => {
	const [search, setSearch] = useState('');

	const overview = trpc.overview.useQuery({ from, to }, { refetchInterval: 5_000 });

	return (
		<>
			<Typography variant="h2">List of subscriptions </Typography>
			<Typography>Filter & deep dive into the subscriptions you&apos;re enrolled to</Typography>

			<ExpensesTable search={search} from={from} to={to} />
		</>
	);
};

import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { CollapsedContent } from '@/molecules/collapsed-content';
import { links } from '@/paths';

export const OnboardingSuccess = () => {
	const navigate = useNavigate();

	return (
		<>
			<Back to={links.onboarding.bankAccounts} />

			<Typography variant="h1" sx={{ mt: 3, mb: 2 }}>
				🎉 Thank you for piloting with us!
			</Typography>
			<Typography variant="input" sx={{ mb: 2 }}>
				Magnet is still in the garage, fine-tuning our data processing engines. Your input is key to helping us
				roll out an awesome product by the end of September 2024. We handle loads of private and sensitive data,
				but no worries—we anonymize everything and use it just for training our models. If you ever need to pull
				the plug, you can revoke access anytime here. Loading your subscriptions
			</Typography>

			<Stack gap={2}>
				<CollapsedContent
					title="What is happening with your data and why are we asking for it?"
					content="Your data is securely stored in our PostgreSQL database and is never shared with third parties or anyone outside of our organization. We request this data to enhance our service by training our statistical models, which helps us provide better and more accurate predictions. Rest assured, your privacy and data security are our top priorities, and we are committed to maintaining the highest standards of protection and confidentiality."
				/>
				<CollapsedContent
					title="What data can Magnet access from e-mails and bank transactions?"
					content="Magnet accesses specific data through your email and bank connections to provide our service effectively. From your email, we access information related to invoices, such as sender details, invoice amounts, dates, and payment terms. From your bank connections, we access transaction details necessary to match and verify subscription payments. We only collect the data essential for managing your subscriptions and ensuring accuracy in our predictions and recommendations. All data is handled with the utmost care and securely stored, following strict privacy and security protocols."
				/>
				<CollapsedContent
					title="Where and how does Magnet store the data?"
					content="Magnet stores your data securely in our Google Firebase database, which is renowned for its robust security measures. All data is encrypted both in transit and at rest to ensure it remains protected from unauthorized access. We adhere to stringent security protocols and regularly update our practices to comply with the latest data protection standards. Your privacy is our priority, and we take all necessary steps to safeguard your information."
				/>
				<CollapsedContent
					title="Who has access to the data?"
					content="Access to your data is strictly limited to authorized personnel within Magnet. We ensure that only essential team members, such as developers and data scientists, have access to the data for purposes of improving our services and maintaining system integrity. No third parties are given access to your data, and we maintain strict confidentiality agreements with our employees to protect your privacy. Our commitment is to keep your data secure and private at all times."
				/>
				<CollapsedContent
					title="Where can I opt out?"
					content="We understand and respect everyone's decision. If you need to pull the plug and revoke the access, you can do it right on this link and we make sure to disconnect accounts and delete all the data."
				/>
			</Stack>

			<Box sx={{ mt: 'auto' }}>
				<Button
					variant="contained"
					startIcon={<ArrowRight />}
					onClick={(e) => {
						e.preventDefault();
						navigate(links.dashboard, { replace: true });
					}}
				>
					Go to dashboard
				</Button>
			</Box>
		</>
	);
};

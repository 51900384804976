import React from 'react';

import { BankAccounts } from '@/templates/bank-accounts';

export const SettingsBankAccounts = () => {
	return (
		<>
			<BankAccounts />
		</>
	);
};

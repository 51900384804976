import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { DashboardLayout } from './layouts/dashboard-layout';
import { LoginLayout } from './layouts/login-layout';
import { OnboardingLayout } from './layouts/onboarding-layout';
import { SettingsLayout } from './layouts/settings-layout';
import { BackofficeHelicopterLogs } from './pages/backoffice/backoffice-helicopter-logs';
import { BackofficeHomepage } from './pages/backoffice/backoffice-homepage';
import { BackofficeOrganizations } from './pages/backoffice/organizations/backoffice-organizations';
import { BackofficeOrganizationsDetail } from './pages/backoffice/organizations/backoffice-organizations-detail';
import { BackofficeOrganizationsExpenseDetail } from './pages/backoffice/organizations/backoffice-organizations-expense-detail';
import { BackofficeOrganizationsExpenses } from './pages/backoffice/organizations/backoffice-organizations-expenses';
import { BackofficeOrganizationsInvoiceDetail } from './pages/backoffice/organizations/backoffice-organizations-invoice-detail';
import { BackofficeOrganizationsInvoices } from './pages/backoffice/organizations/backoffice-organizations-invoices';
import { BackofficeOrganizationsMailbox } from './pages/backoffice/organizations/backoffice-organizations-mailbox';
import { BackofficeOrganizationsMailboxDetail } from './pages/backoffice/organizations/backoffice-organizations-mailbox-detail';
import { BackofficeOrganizationsTransactionDetail } from './pages/backoffice/organizations/backoffice-organizations-transaction-detail';
import { BackofficeOrganizationsTransactions } from './pages/backoffice/organizations/backoffice-organizations-transactions';
import { BackofficeOrganizationsUploads } from './pages/backoffice/organizations/backoffice-organizations-uploads';
import { BackofficeTools } from './pages/backoffice/organizations/backoffice-tools';
import { BackofficeUsers } from './pages/backoffice/users/backoffice-users';
import { Dashboard } from './pages/dashboard';
import { GoCardlessCallback } from './pages/go-cardless-callback';
import { Login } from './pages/login';
import { NotFound } from './pages/not-found';
import { OnboardingBankAccounts } from './pages/onboarding/onboarding-bank-accounts';
import { OnboardingMailboxes } from './pages/onboarding/onboarding-mailboxes';
import { OnboardingOrganization } from './pages/onboarding/onboarding-organization';
import { OnboardingSuccess } from './pages/onboarding/onboarding-success';
import { Profile } from './pages/profile';
import { SettingsBankAccounts } from './pages/settings/settings-bank-accounts';
import { SettingsCurrencies } from './pages/settings/settings-currencies';
import { SettingsMailboxes } from './pages/settings/settings-mailboxes';
import { SettingsMe } from './pages/settings/settings-me';
import { SettingsUsers } from './pages/settings/settings-users';
import { links, paths } from './paths';
import { ProtectedRoutes, PublicOnlyRoutes } from './utils/protected-routes';

const routes: RouteObject[] = [
	{
		element: <ProtectedRoutes />,
		children: [
			{
				element: <DashboardLayout />,
				children: [
					{ path: paths.dashboard, element: <Dashboard /> },
					{ path: paths.subscriptions, element: null },
					{ path: paths.invoices, element: null },
					{ path: paths.profile, element: <Profile /> },
					{ path: paths.billing, element: null },
					{
						path: paths.settings.ROOT,
						element: <SettingsLayout />,
						children: [
							{ element: <SettingsUsers />, index: true },
							{ element: <SettingsUsers />, path: paths.settings.users },
							{ element: <SettingsMailboxes />, path: paths.settings.mailboxes },
							{ element: <SettingsBankAccounts />, path: paths.settings.bankAccounts },
							{ element: <SettingsCurrencies />, path: paths.settings.currencies },
						],
					},
					{
						path: paths.settings.ROOT,
						children: [{ element: <SettingsMe />, path: paths.settings.me }],
					},
				],
			},
		],
	},
	{
		element: <ProtectedRoutes onboardingRequired={false} />,
		path: paths.onboarding.ROOT,
		children: [
			{
				element: <OnboardingLayout activeStep={1} />,
				children: [{ path: paths.onboarding.organization, element: <OnboardingOrganization /> }],
			},
			{
				element: <OnboardingLayout activeStep={2} />,
				children: [{ path: paths.onboarding.mailboxes, element: <OnboardingMailboxes /> }],
			},
			{
				element: <OnboardingLayout activeStep={3} />,
				children: [{ path: paths.onboarding.bankAccounts, element: <OnboardingBankAccounts /> }],
			},
			{
				element: <OnboardingLayout activeStep={4} />,
				children: [{ path: paths.onboarding.success, element: <OnboardingSuccess /> }],
			},
			{
				path: '',
				element: <Navigate to={links.onboarding.organization} />,
			},
		],
	},
	{
		element: <ProtectedRoutes onboardingRequired={false} />,
		path: paths.backoffice.ROOT,
		children: [
			{
				element: <DashboardLayout />,
				children: [
					{
						path: paths.backoffice.organizations.ROOT,
						children: [
							{
								path: paths.backoffice.organizations.detail.ROOT,
								children: [
									{ element: <BackofficeOrganizationsDetail />, index: true },
									{
										path: paths.backoffice.organizations.detail.mailbox.ROOT,
										children: [
											{ index: true, element: <BackofficeOrganizationsMailbox /> },
											{
												path: paths.backoffice.organizations.detail.mailbox.detail,
												element: <BackofficeOrganizationsMailboxDetail />,
											},
										],
									},
									{
										path: paths.backoffice.organizations.detail.transactions.ROOT,
										children: [
											{ index: true, element: <BackofficeOrganizationsTransactions /> },
											{
												path: paths.backoffice.organizations.detail.transactions.detail,
												element: <BackofficeOrganizationsTransactionDetail />,
											},
										],
									},
									{
										path: paths.backoffice.organizations.detail.uploads.ROOT,
										children: [
											{ index: true, element: <BackofficeOrganizationsUploads /> },
											// {
											// 	path: paths.backoffice.organizations.detail.invoices.detail,
											// 	element: <BackofficeOrganizationsInvoiceDetail />,
											// },
										],
									},
									{
										path: paths.backoffice.organizations.detail.invoices.ROOT,
										children: [
											{ index: true, element: <BackofficeOrganizationsInvoices /> },
											{
												path: paths.backoffice.organizations.detail.invoices.detail,
												element: <BackofficeOrganizationsInvoiceDetail />,
											},
										],
									},
									{
										path: paths.backoffice.organizations.detail.expenses.ROOT,
										children: [
											{ index: true, element: <BackofficeOrganizationsExpenses /> },
											{
												path: paths.backoffice.organizations.detail.expenses.detail,
												element: <BackofficeOrganizationsExpenseDetail />,
											},
										],
									},
								],
							},
							{ index: true, element: <BackofficeOrganizations /> },
						],
					},
					{
						path: paths.backoffice.users.ROOT,
						children: [{ index: true, element: <BackofficeUsers /> }],
					},
					{
						path: paths.backoffice.tools.ROOT,
						children: [{ index: true, element: <BackofficeTools /> }],
					},
					{
						path: paths.backoffice.helicopterLogs.ROOT,
						children: [{ index: true, element: <BackofficeHelicopterLogs /> }],
					},
					{ index: true, element: <BackofficeHomepage /> },
				],
			},
		],
	},
	{
		element: <PublicOnlyRoutes />,
		children: [
			{
				element: <LoginLayout />,
				children: [{ path: paths.login, element: <Login /> }],
			},
		],
	},
	{
		path: paths.notFound,
		element: <LoginLayout />,
		children: [{ path: '', element: <NotFound /> }],
	},
	{
		path: '/gocardless/callback',
		element: <GoCardlessCallback />,
	},
	{
		path: '*',
		element: <Navigate to={links.notFound} />,
	},
];

export const Routes = () => useRoutes(routes);

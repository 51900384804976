import React from 'react';
import { Avatar, Backdrop, Box, Checkbox, Divider, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { truncate } from 'lodash';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { Circle } from '@/atoms/circle';
import { Icon } from '@/atoms/icon';
import { Row } from '@/atoms/row';
import { getInvoiceLink } from '@/utils/attachment-file-link';
import { config } from '@/utils/config';
import { centsToAmount } from '@/utils/monetary';
import { border } from '@/utils/sx';
import { amethyst } from '@/utils/theme';

import { ExpenseActionsDropdown } from './expense-table.action-dropdown';
import { useExpenseTableRowPair } from './expense-table.row-pair';
import { Expense } from './expense-table.types';

export const rowGridSx: SxProps = {
	'display': 'grid',
	'grid-template-columns': '40px 0.7fr 100px 1fr 1fr 110px',
	'borderRadius': 3,
};

const xSx: SxProps = {
	borderRadius: 2,
	px: 1.5,
	py: 1.25,
};

type Props = { expense: Expense };

export const ExpenseRow = ({ expense }: Props) => {
	const { id, tool, invoice, bankPaymentTransaction } = expense;

	let variant: 'default' | 'warning' = 'default';
	if (!invoice.id || !bankPaymentTransaction.id) variant = 'warning';

	const xxSx = {
		...xSx,
		bgcolor: variant === 'default' ? '#EEF2F3' : 'rgba(255, 255, 255, 0.7)',
	};

	const dividerSx = { my: 1.5, borderColor: 'text.disabled', borderBottomWidth: '0.5px' };

	const toolNameAvatar = tool?.name[0].toUpperCase();

	const downloadInvoice = () => {
		if (!invoice.id) return;
		window.open(getInvoiceLink(invoice.id));
	};

	const { onOpen, element, open } = useExpenseTableRowPair({ expenseId: id });

	const onPair = (e: React.MouseEvent, type: 'bank-transaction' | 'invoice') => {
		onOpen(e.currentTarget as HTMLButtonElement, type);
	};

	return (
		<Box
			sx={{
				...rowGridSx,
				...border(variant === 'default' ? 'text.disabled' : amethyst['300']),
				bgcolor: variant === 'default' ? 'transparent' : amethyst['50'],
				p: 1.5,
				gap: 1,
			}}
		>
			{/* select */}
			<Box sx={{ py: 1 }}>
				<Checkbox />
			</Box>
			{/* tool */}
			<Box sx={{ py: 1.75 }}>
				<Row gap={1}>
					<Avatar
						alt={tool?.name}
						src={tool?.logoUrl}
						sx={{
							'& img': { objectFit: 'contain' },
							'width': '1.9rem',
							'height': '1.9rem',
							...border('text.disabled'),
						}}
					>
						{toolNameAvatar}
					</Avatar>
					<Stack>
						<Typography variant="tableLarge">{tool?.name}</Typography>
						<Typography variant="tableAdditional">{tool?.subtitle}</Typography>
					</Stack>
				</Row>
			</Box>
			{/* status */}
			<Box></Box>
			{/* payment transaction */}
			{bankPaymentTransaction.id ? (
				<>
					<Stack sx={{ ...xxSx }}>
						<Row sx={{ justifyContent: 'space-between' }}>
							<Typography variant="tableLarge">
								{truncate(bankPaymentTransaction.label, { length: 24 })}
							</Typography>
							<Row gap={0.5}>
								<Typography variant="tableLarge">
									<FormattedNumber
										value={-1 * centsToAmount(Number(bankPaymentTransaction.amount))}
										style="currency"
										currency={bankPaymentTransaction.currency}
									/>
								</Typography>
							</Row>
						</Row>
						<Divider sx={dividerSx} />
						<Row sx={{ justifyContent: 'space-between' }}>
							<Box>
								<Typography variant="tableAdditional">Account</Typography>
								<Typography variant="tableSmall">
									{bankPaymentTransaction.accountName} (
									{bankPaymentTransaction.accountInstitutionName}{' '}
									{bankPaymentTransaction.accountCurrency})
								</Typography>
							</Box>
							<Box>
								<Typography variant="tableAdditional">Date</Typography>
								<Typography variant="tableSmall">
									{bankPaymentTransaction.date ? (
										<FormattedDate value={bankPaymentTransaction.date} />
									) : null}
								</Typography>
							</Box>
						</Row>
					</Stack>
				</>
			) : (
				<>
					<Stack
						sx={{ ...xSx, bgcolor: amethyst[100], justifyContent: 'center', p: 2.5, cursor: 'pointer' }}
						onClick={(e) => onPair(e, 'bank-transaction')}
					>
						<Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="tableLarge">Pair bank transaction</Typography>
							<Circle sx={{ bgcolor: amethyst[600], fontSize: 70, width: '2.5rem', height: '2.5rem' }}>
								<Icon name="ChevronDown" />
							</Circle>
						</Row>
					</Stack>
				</>
			)}
			{/* invoice */}
			{invoice.id ? (
				<Stack sx={{ ...xxSx }}>
					<Row sx={{ justifyContent: 'space-between' }}>
						<Row gap={0.25}>
							<Typography variant="tableLarge">{invoice.filename}</Typography>
							<Tooltip title="Open invoice">
								<Icon
									name="Eye"
									sx={{ cursor: 'pointer', color: 'primary.600' }}
									onClick={downloadInvoice}
								/>
							</Tooltip>
						</Row>
						<Row gap={0.5}>
							<Typography variant="tableLarge">
								<FormattedNumber
									value={Number(invoice.totalAmount) / 100}
									style="currency"
									currency={invoice.currency}
								/>
							</Typography>
							{invoice.totalAmountOnInvoice !== invoice.totalAmount && (
								<Typography variant="tableMedium">
									(
									<FormattedNumber
										value={Number(invoice.totalAmountOnInvoice) / 100}
										style="currency"
										currency={invoice.currencyOnInvoice}
									/>
									)
								</Typography>
							)}
						</Row>
					</Row>
					<Divider sx={dividerSx} />
					<Row sx={{ justifyContent: 'space-between' }}>
						<Box>
							<Typography variant="tableAdditional">From</Typography>
							<Typography variant="tableSmall">{invoice.from}</Typography>
						</Box>
						<Box>
							<Typography variant="tableAdditional">Date</Typography>
							<Typography variant="tableSmall">
								{invoice.receivedAt ? <FormattedDate value={invoice.receivedAt} /> : null}
							</Typography>
						</Box>
					</Row>
				</Stack>
			) : (
				<Stack
					sx={{ ...xSx, bgcolor: amethyst[100], justifyContent: 'center', p: 2.5, cursor: 'pointer' }}
					onClick={(e) => onPair(e, 'invoice')}
				>
					<Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
						<Typography variant="tableLarge">Pair invoice</Typography>
						<Circle sx={{ bgcolor: amethyst[600], fontSize: 70, width: '2.5rem', height: '2.5rem' }}>
							<Icon name="ChevronDown" />
						</Circle>
					</Row>
				</Stack>
			)}
			{/* actions */}
			<Row sx={{ alignItems: 'flex-start', justifyContent: 'flex-end' }}>
				<ExpenseActionsDropdown expense={expense} />
			</Row>

			<Backdrop open={open}>{element}</Backdrop>
		</Box>
	);
};

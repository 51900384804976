import React from 'react';
import { Avatar, Button, Stack, Typography } from '@mui/material';
import { ChevronDown, Plus } from 'lucide-react';

import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Row } from '@/atoms/row';
import { TableHead, TableRow } from '@/molecules/table';
import { trpc } from '@/trpc/client';
import { useConnectMailbox } from '@/utils/mailbox';

const columns = '1fr 160px 160px 160px 120px';

export const Mailboxes = () => {
	const { data } = trpc.mailboxesDetailedList.useQuery(undefined, { refetchInterval: 1_000 });

	const { connectMailbox } = useConnectMailbox();

	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Mailboxes / Emails
			</Typography>
			<Typography variant="input" sx={{ mb: 3 }}>
				Filter & deep dive into the subscriptions you&apos;re enrolled to
			</Typography>

			<TableHead
				items={['User name', 'Subscriptions', 'Last synced', 'Mailbox added', '']}
				columns={columns}
				sx={{ mt: 3 }}
			/>

			{data &&
				data.map(({ id, email, messagesCount, lastSyncAt, createdAt }) => {
					return (
						<TableRow columns={columns} key={id}>
							<Row gap={1}>
								<Avatar src={''} sx={{ width: 32, height: 32 }} />
								<Stack sx={{ width: '100%' }}>
									<Typography
										variant="tableLarge"
										sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
									>
										{email}
									</Typography>
									<Typography variant="tableAdditional">{email}</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableMedium" sx={{ color: 'text.primary' }}>
										{messagesCount}
									</Typography>
									<Typography variant="tableAdditional">{messagesCount} emails</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableSmall">
										<FormattedDatetime value={lastSyncAt} />
									</Typography>
									<Typography variant="tableAdditional" sx={{}}>
										<FormattedDatetime value={lastSyncAt} />
									</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableSmall">
										<FormattedDatetime value={createdAt} />
									</Typography>
									<Typography variant="tableAdditional" sx={{}}>
										<FormattedDatetime value={createdAt} />
									</Typography>
								</Stack>
							</Row>
							<Row sx={{ justifyContent: 'flex-end' }}>
								<Button variant="contained" endIcon={<ChevronDown />} size="small">
									Manage
								</Button>
							</Row>
						</TableRow>
					);
				})}

			<Row sx={{ mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>
				<Button variant="contained" size="small" endIcon={<Plus />} onClick={() => connectMailbox()}>
					Add mailbox
				</Button>

				{data && <Typography variant="body2">{data.length} mailboxes in total</Typography>}
			</Row>
		</>
	);
};

import React, { useState } from 'react';
import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { useSnackbar } from 'notistack';

import { Row } from '@/atoms/row';
import { trpc } from '@/trpc/client';

import { Expense } from './expense-table.types';

type Props = {
	expense: Expense;
};

export const ExpenseActionsDropdown = ({ expense: { id, invoiceId, bankPaymentTransactionId } }: Props) => {
	const { enqueueSnackbar } = useSnackbar();
	const utils = trpc.useUtils();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const { mutate: hide, isLoading: isLoadingHiding } = trpc.expenses.hide.useMutation({
		onSuccess() {
			utils.expenses.list.invalidate();
			enqueueSnackbar('Expense hidden');
		},
		onError() {
			enqueueSnackbar('Error hiding expense');
		},
	});

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleHide = () => {
		hide({ expenseId: id }, { onSuccess: handleClose });
	};

	return (
		<>
			<Button variant="contained" size="small" endIcon={<ChevronDown />} onClick={handleClick}>
				Manage
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{invoiceId && <MenuItem onClick={() => handleClose()}>Unpair invoice</MenuItem>}
				{bankPaymentTransactionId && (
					<MenuItem onClick={() => handleClose()}>Unpair payment transaction</MenuItem>
				)}
				<MenuItem onClick={() => handleHide()}>
					<Row gap={0.5}>
						{isLoadingHiding && <CircularProgress size="1rem" />}
						<span>Hide from this list</span>
					</Row>
				</MenuItem>
			</Menu>
		</>
	);
};

import React from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Icon } from '@/atoms/icon';
import { SimpleTable } from '@/molecules/simple-table';
import { BackofficeProcessingResultsTable } from '@/organisms/backoffice/backoffice-processing-results-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';

export const BackofficeOrganizationsTransactionDetail = () => {
	const { id: organizationId, transactionId } = useParams<{ id: string; transactionId: string }>();
	if (!organizationId || !transactionId) throw new Error('No organization or transaction id');

	const confirm = useConfirm();

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({
		id: organizationId,
	});
	const { data, isError } = trpc.backoffice.organizations.transactions.get.useQuery({
		id: transactionId,
	});

	const { mutateAsync: extract } = trpc.backoffice.organizations.transactions.extract.useMutation();

	return (
		<>
			<Back to={links.backoffice.organizations.detail.transactions.ROOT.replace(':id', organizationId)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Payment Transaction of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<Typography variant="h3">Overview</Typography>

			{data && (
				<SimpleTable
					rows={[
						['ID', data.id],
						[
							'Amount',
							<FormattedNumber
								value={centsToAmount(Number(data.amount))}
								style="currency"
								currency={data.currency}
							/>,
						],
						['Internal Transaction Id', data.internalTransactionId],
						['Additional Information', data.additionalInformation],
						['Remittance Information Unstructured', data.remittanceInformationUnstructured],
						['Booking Date', data.bookingDate],
						['Value Date', data.valueDate],
					]}
					sx={{ maxWidth: 900 }}
				/>
			)}

			<Typography variant="h3">Processing Results</Typography>

			<IconButton
				onClick={() => {
					extract({ id: transactionId });
				}}
				sx={{ fontSize: 14 }}
			>
				<Icon name="RefreshCw" />
			</IconButton>

			<BackofficeProcessingResultsTable bankPaymentTransactionId={transactionId} />
		</>
	);
};

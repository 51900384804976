import React from 'react';
import { Button, styled } from '@mui/material';
import { CloudUpload } from 'lucide-react';
import { useSnackbar } from 'notistack';

import { trpc } from '@/trpc/client';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function BackofficeUploadFile({ organizationId }: { organizationId: string }) {
	const { enqueueSnackbar } = useSnackbar();

	const { mutate } = trpc.backoffice.organizations.uploads.upload.useMutation({
		onSuccess() {
			enqueueSnackbar('File uploaded');
		},
		onError(error) {
			enqueueSnackbar('File upload failed', { variant: 'error' });
			console.error(error);
		},
	});

	const upload = async (files: FileList) => {
		for (const file of Array.from(files)) {
			const filename = file.name;
			const contentType = file.type;
			const content = await new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result as string);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});

			enqueueSnackbar('Uploading file...');

			mutate({ content: content.split(',')[1], contentType, filename, organizationId });
		}
	};

	return (
		<Button
			component="label"
			role={undefined}
			variant="contained"
			tabIndex={-1}
			startIcon={<CloudUpload />}
			sx={{ mb: 2 }}
		>
			Upload files
			<VisuallyHiddenInput type="file" onChange={(event) => upload(event.target.files)} multiple />
		</Button>
	);
}

import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Link } from '@/atoms/link-component';
import { SimpleTable } from '@/molecules/simple-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['list']['data'][number];

export const BackofficeOrganizations = () => {
	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isLoading } = trpc.backoffice.organizations.list.useQuery({ page, pageSize });

	const rowCount = usePaginationRowCount(data?.pagination.count);

	const columns: GridColDef<Item>[] = [
		{
			field: 'id',
			headerName: 'Name',
			flex: 1,
			renderCell({ value, row: { name } }) {
				return <Link to={links.backoffice.organizations.detail.ROOT.replace(':id', value)}>{name}</Link>;
			},
		},
		{ field: 'usersCount', headerName: 'Users' },
		{ field: 'mailboxesCount', headerName: 'Mailboxes' },
		{ field: 'bankAccountsCount', headerName: 'Bank Accounts' },
		{
			field: 'createdAt',
			headerName: 'Created at',
			flex: 1,
			renderCell({ value }) {
				return <FormattedDatetime value={value} />;
			},
		},
	];

	return (
		<>
			<Back to={links.backoffice.ROOT} />
			<Typography variant="h2" sx={{ mb: 2 }}>
				Organizations
			</Typography>

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<DataGrid
					rows={data?.data ?? []}
					loading={isLoading}
					columns={columns}
					density="compact"
					rowCount={rowCount}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={onPaginationModelChange}
					rowSelection={false}
				/>
			)}
		</>
	);
};

import React from 'react';
import { Alert, IconButton, Tooltip, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Icon } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { SimpleTable } from '@/molecules/simple-table';
import { BackofficeProcessingResultsTable } from '@/organisms/backoffice/backoffice-processing-results-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';

export const BackofficeOrganizationsExpenseDetail = () => {
	const { id: organizationId, expenseId } = useParams<{ id: string; expenseId: string }>();
	if (!organizationId || !expenseId) throw new Error('No organization or transaction id');

	const confirm = useConfirm();

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({
		id: organizationId,
	});
	const { data, isError } = trpc.backoffice.organizations.expenses.get.useQuery({
		id: expenseId,
	});

	const { mutate: match } = trpc.backoffice.organizations.expenses.match.useMutation();

	return (
		<>
			<Back to={links.backoffice.organizations.detail.expenses.ROOT.replace(':id', organizationId)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Expense of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<Typography variant="h3">Overview</Typography>

			{data && (
				<SimpleTable
					rows={[
						['ID', data.id],
						['Vendor', data.vendor],
						[
							'Amount',
							<FormattedNumber
								value={centsToAmount(Number(data.amount))}
								style="currency"
								currency={data.currency}
							/>,
						],
						[
							'Amount (USD)',
							<FormattedNumber
								value={centsToAmount(Number(data.amountUsd))}
								style="currency"
								currency="USD"
							/>,
						],
						['Invoice ID', data.invoiceId],
						['Bank Payment Transaction ID', data.bankPaymentTransactionId],
					]}
					sx={{ maxWidth: 900 }}
				/>
			)}

			<Typography variant="h3">Processing Results</Typography>

			<Tooltip title="Run expense matching">
				<IconButton
					onClick={() => {
						match({ expenseId });
					}}
					sx={{ fontSize: 14 }}
				>
					<Icon name="RefreshCw" />
				</IconButton>
			</Tooltip>

			{/* <BackofficeProcessingResultsTable invoiceId={invoiceId} /> */}
		</>
	);
};

import React from 'react';
import { Alert, Avatar, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ChevronDown, Plus } from 'lucide-react';

import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Link } from '@/atoms/link-component';
import { Row } from '@/atoms/row';
import { TableHead, TableRow } from '@/molecules/table';
import { trpc } from '@/trpc/client';

const columns = 'repeat(4, 1fr)';

export const Members = () => {
	const { data, isLoading, isError } = trpc.organization.listMembers.useQuery();

	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				List of users
			</Typography>
			<Typography variant="input" sx={{ mb: 3 }}>
				Filter & deep dive into the subscriptions you&apos;re enrolled to
			</Typography>

			{isError && <Alert severity="error">Failed to load members</Alert>}
			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			<TableHead items={['User name', 'Role', 'Account created', '']} columns={columns} sx={{ mt: 3 }} />

			{data &&
				data.map(({ displayName, email, id, role, assignedToOrganizationAt }) => {
					return (
						<TableRow columns={columns} key={id}>
							<Row gap={1}>
								<Avatar src={''} sx={{ width: 32, height: 32 }} />
								<Stack>
									<Typography variant="tableLarge">{displayName}</Typography>
									<Typography variant="tableAdditional">{email}</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableMedium" sx={{ color: 'text.primary' }}>
										{role}
									</Typography>
									<Typography variant="tableAdditional">
										<Link to="#">Edit</Link>
									</Typography>
								</Stack>
							</Row>
							<Row>
								<Stack gap={0.25}>
									<Typography variant="tableSmall">
										<FormattedDatetime value={assignedToOrganizationAt} />
									</Typography>
									<Typography variant="tableAdditional" sx={{}}>
										<FormattedDatetime value={assignedToOrganizationAt} />
									</Typography>
								</Stack>
							</Row>
							<Row sx={{ justifyContent: 'flex-end' }}>
								<Button variant="contained" endIcon={<ChevronDown />} size="small">
									Manage
								</Button>
							</Row>
						</TableRow>
					);
				})}

			<Row sx={{ mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>
				<Button variant="contained" endIcon={<Plus />} size="small">
					Add user
				</Button>

				{data && <Typography variant="body2">{data.length} user in total</Typography>}
			</Row>
		</>
	);
};

import React, { useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { last } from 'lodash';
import { useSnackbar } from 'notistack';

import { Icon } from '@/atoms/icon';
import { Row } from '@/atoms/row';
import { BackofficeEvalGroundTruthDialog } from '@/molecules/backoffice/backoffice-eval-ground-truth-dialog';
import { trpc } from '@/trpc/client';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';

type EvalGroundTruthCol =
	RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number]['evalGroundTruth'];

type Props = {
	value: EvalGroundTruthCol;
	emailMessageId?: string;
	bankPaymentTransactionId?: string;
};

export const BackofficeEvalGroundTruthCol = ({ emailMessageId, bankPaymentTransactionId, value }: Props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [dialogOpen, setDialogOpen] = useState(false);

	const entityName = emailMessageId ? 'email message' : 'bank payment transaction';

	const utils = trpc.useUtils();
	const { mutate, isLoading } = trpc.backoffice.evalGroundTruth.create.useMutation({
		onSuccess() {
			setDialogOpen(false);

			enqueueSnackbar('Eval ground truth updated');

			utils.backoffice.organizations.mailbox.list.invalidate();
			utils.backoffice.organizations.transactions.list.invalidate();
		},
		onError() {
			enqueueSnackbar('Failed to update eval ground truth', { variant: 'error' });
		},
	});

	const disabled = isLoading;

	const handleThumbDown = () => {
		mutate({
			emailMessageId,
			bankPaymentTransactionId,
			isInvoice: false,
			isSaasPayment: false,
		});
	};

	const handleThumbUp = () => {
		setDialogOpen(true);
	};

	const handleEditClick = () => {
		setDialogOpen(true);
	};

	const lastEval = last(value);

	return (
		<>
			{lastEval ? (
				<Box sx={{ py: 0.5 }}>
					<Chip
						sx={{ height: 'auto', px: 0.5, py: 0.5, cursor: 'pointer' }}
						onClick={handleEditClick}
						label={
							<>
								Invoice: <b>{lastEval.isInvoice ? 'YES' : 'NO'}</b>
								<br />
								SaaS: <b>{lastEval.isSaasPayment ? 'YES' : 'NO'}</b>
								<br />
								Tool: <b>{lastEval.toolName ?? '---'}</b>
							</>
						}
						size="small"
					/>
				</Box>
			) : (
				<>
					<Row alignItems="center">
						<IconButton size="small" disabled={disabled} onClick={handleThumbDown}>
							<Icon name="ThumbsDown" />
						</IconButton>

						<IconButton size="small" disabled={disabled} onClick={handleThumbUp}>
							<Icon name="ThumbsUp" />
						</IconButton>
					</Row>
				</>
			)}

			<BackofficeEvalGroundTruthDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				title={<>Are you sure you want to "thumb up" {entityName}</>}
				onSubmit={({ isInvoice, isSaasPayment, toolId }) => {
					mutate({
						emailMessageId,
						bankPaymentTransactionId,
						isInvoice,
						isSaasPayment,
						toolId,
					});
				}}
				defaultValues={
					lastEval
						? {
								isInvoice: lastEval.isInvoice,
								isSaasPayment: lastEval.isSaasPayment,
								toolId: lastEval.toolId,
							}
						: undefined
				}
			/>
		</>
	);
};

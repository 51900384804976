import React from 'react';
import { Alert, IconButton, Link as MUILink, Skeleton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Icon } from '@/atoms/icon';
import { SimpleTable } from '@/molecules/simple-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';

type Props = {
	organizationId: string;
};

export const BackofficeOrganizationBankAccounts = ({ organizationId }: Props) => {
	const { enqueueSnackbar } = useSnackbar();

	const { data, isLoading, isError } = trpc.backoffice.bankAccounts.list.useQuery({ organizationId });

	const triggerTransactionFetch = trpc.backoffice.bankAccounts.triggerTransactionsFetch.useMutation({
		onSuccess() {
			enqueueSnackbar('Triggered transaction fetch');
		},
	});

	return (
		<>
			<Typography variant="h3">
				Bank Accounts
				<Link to={links.backoffice.organizations.detail.transactions.ROOT.replace(':id', organizationId)}>
					<IconButton sx={{ ml: 1 }}>
						<Icon name="LogIn" />
					</IconButton>
				</Link>
			</Typography>

			{isError && <Alert severity="error">There was an error</Alert>}

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<SimpleTable
					rows={[
						['Institution', 'Currency', 'External Reference Id', 'Transactions', 'Last Sync At', ''],
						...data.map(
							({
								id,
								name,
								institutionName,
								currency,
								externalReferenceId,
								paymentTransactionsCount,
								lastSyncAt,
							}) => {
								return [
									institutionName,
									currency,
									externalReferenceId,
									String(paymentTransactionsCount),
									lastSyncAt && <FormattedDatetime value={lastSyncAt} />,
									<MUILink
										href="#"
										onClick={(e) => {
											e.preventDefault();
											triggerTransactionFetch.mutate({ id });
										}}
									>
										Trigger sync
									</MUILink>,
								];
							},
						),
					]}
					firstLineBold
				/>
			)}
		</>
	);
};

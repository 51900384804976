import React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { first } from 'lodash';

import { FormattedDatetime } from '@/atoms/formatted-datetime';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';

type Value = RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number]['processingResults'];

type Props = {
	value: Value;
};

export const BackofficeProcessingResultCol = ({ value }: Props) => {
	const lastValue = first(value);
	if (!lastValue) return null;

	const formattedResult = Object.entries(lastValue.result).map(([key, value]) => {
		return (
			<>
				{key}: <b>{JSON.stringify(value)}</b>
				<br />
			</>
		);
	});

	return (
		<Box sx={{ p: 0.5 }}>
			<Tooltip
				title={
					<>
						{lastValue.type}
						<br />
						<FormattedDatetime value={lastValue.createdAt} />
					</>
				}
			>
				<Chip sx={{ height: 'auto', p: 0.5 }} label={<>{formattedResult}</>} />
			</Tooltip>
		</Box>
	);
};

import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';

type Props = {
	name: string;
	label: string;
};

export const FormCheckbox = ({ name, label }: Props) => {
	return (
		<Controller
			name={name}
			render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
				let helperText: string | undefined;
				if (Array.isArray(error) && error.length) {
					const firstErrorMessage = (error as FieldError[]).find((e) => e?.message);
					if (firstErrorMessage?.message) helperText = firstErrorMessage.message;
				} else if (error?.message) {
					helperText = error.message;
				}

				return (
					<FormControl error={Boolean(error)} component="fieldset" variant="standard">
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={Boolean(value)}
										onChange={(_, newValue) => {
											onChange(newValue);
										}}
										name={name}
									/>
								}
								label={label}
							/>
						</FormGroup>
						{helperText && <FormHelperText>{helperText}</FormHelperText>}
					</FormControl>
				);
			}}
		/>
	);
};

import React from 'react';
import { Avatar, Box, Button, Skeleton, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['helicopterLogs']['data'][number];

export const BackofficeHelicopterLogs = () => {
	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isLoading } = trpc.backoffice.helicopterLogs.useQuery({ page, pageSize });

	const rowCount = usePaginationRowCount(data?.pagination.count);

	const columns: GridColDef<Item>[] = [
		{ field: 'endpoint', flex: 1 },
		{ field: 'payload', flex: 1, renderCell: ({ value }) => JSON.stringify(value) },
		{ field: 'status', flex: 1 },
		{ field: 'response', flex: 1 },
		{
			field: 'createdAt',
			headerName: 'Created at',
			flex: 1,
			renderCell({ value }) {
				return <FormattedDatetime value={value} />;
			},
		},
	];

	const onCreate = () => {};

	return (
		<>
			<Back to={links.backoffice.ROOT} />
			<Typography variant="h2" sx={{ mb: 1 }}>
				Helicopter logs
			</Typography>

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<DataGrid
					rows={data?.data ?? []}
					loading={isLoading}
					columns={columns}
					density="compact"
					rowCount={rowCount}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={onPaginationModelChange}
				/>
			)}
		</>
	);
};

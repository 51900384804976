import React, { Fragment } from 'react';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';

import { Icon } from '@/atoms/icon';
import { LoginButton } from '@/atoms/login-button';
import { Row } from '@/atoms/row';
import { useAuth } from '@/providers/auth-provider';
import { trpc } from '@/trpc/client';
import { useConnectMailbox } from '@/utils/mailbox';
import { border, center, vCenter } from '@/utils/sx';
import { aquamarine } from '@/utils/theme';

export const OnboardingMailboxes = () => {
	const { user } = useAuth();

	const { data } = trpc.mailboxesDetailedList.useQuery(undefined, { refetchInterval: 5_000 });
	const { connectMailbox, isLoading: connectingMailbox } = useConnectMailbox();

	const mailboxUser = (data ?? []).find(({ email }) => email === user.email);
	const mailboxesNonUser = (data ?? []).filter(({ email }) => email !== user.email);

	const mailboxes: { connected: boolean; email: string; messagesCount?: number; invoicesCount?: number }[] = [
		mailboxUser ? { connected: true, ...mailboxUser } : { email: user.email, connected: false },
		...mailboxesNonUser.map((mailbox) => {
			return { connected: true, ...mailbox };
		}),
	];

	return (
		<Box
			sx={{
				mt: 3,
				display: 'grid',
				gridTemplateColumns: '2rem 1fr',
				rowGap: 1,
				alignItems: 'center',
			}}
		>
			{mailboxes.map(({ email, connected, messagesCount, invoicesCount }, i) => {
				return (
					<Fragment key={email}>
						<Typography variant="input">{i + 1}</Typography>
						<MailboxLine mailbox={{ email, connected, messagesCount, invoicesCount }} />
					</Fragment>
				);
			})}

			<>
				<Typography variant="input">{mailboxes.length + 1}</Typography>
				<Box>
					<LoginButton size="small" disabled={connectingMailbox} onClick={() => connectMailbox()}>
						Sign in with Google
					</LoginButton>
				</Box>
			</>
		</Box>
	);
};

type MailboxLineOptions = {
	email: string;
	connected: boolean;
	messagesCount?: number;
	invoicesCount?: number;
};

type MailboxLineProps = { mailbox: MailboxLineOptions };

const MailboxLine = ({ mailbox: { email, connected, messagesCount, invoicesCount } }: MailboxLineProps) => {
	const { connectMailbox, isLoading: connectingMailbox } = useConnectMailbox();

	return (
		<Box
			sx={{
				height: '3.25rem',
				bgcolor: 'grey.50',
				...border('grey.200'),
				borderRadius: '1.625rem',
				...vCenter,
				px: 1.75,
			}}
		>
			<Stack sx={{ pl: 1 }}>
				<Typography variant="body3">Email</Typography>
				<Typography variant="body2">{email}</Typography>
			</Stack>

			{connected && (
				<Row sx={{ ml: 'auto' }} gap={2}>
					<Button variant="link">Disconnect</Button>

					<Chip
						sx={{ bgcolor: aquamarine['300'], color: 'secondary.contrastText' }}
						label={
							<Box sx={{ ...center, pl: 0.75 }}>
								<Typography variant="body3" sx={{ fontWeight: 500 }}>
									Access granted
								</Typography>
								<Icon name="CheckCheck" sx={{ fontSize: '1rem', mr: -0.25 }} />
							</Box>
						}
					/>
				</Row>
			)}

			{!connected && (
				<LoginButton
					size="small"
					disabled={connectingMailbox}
					onClick={() => connectMailbox({ loginHint: email })}
					sx={{ ml: 'auto', mr: -1.75 }}
				>
					Sign in with Google
				</LoginButton>
			)}
		</Box>
	);
};

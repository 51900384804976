import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';

import { LoginButton } from '@/atoms/login-button';
import { Logo } from '@/atoms/logo';

import { useAuth } from '../providers/auth-provider';

export const Login = () => {
	const { triggerGoogleLogin } = useAuth();

	return (
		<Box sx={{ color: 'white', height: '100vh', display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ height: 130, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
				<Logo />
			</Box>

			<Box
				sx={{
					mx: 'auto',
					mt: 16,
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					flex: 1,
				}}
			>
				<Typography variant="h1" sx={{ fontSize: '4.5rem', fontWeight: 350 }}>
					Let&apos;s get you started
				</Typography>

				<Typography variant="body1" sx={{ mt: 4, mb: 8, maxWidth: 550, color: 'grey.300' }}>
					Streamline your business operations with our all-in-one subscription management platform, tailored
					specifically for companies.
				</Typography>

				<LoginButton onClick={triggerGoogleLogin}>Sign in with Google</LoginButton>

				<Typography
					variant="body2"
					sx={{ fontSize: '0.75rem', mt: 'auto', mb: 8, maxWidth: 550, color: 'grey.300' }}
				>
					By signing in, you acknowledge and agree to our
					<br />
					<Link href="https://getmagnet.io/privacy-policy" target="_blank" color="inherit" underline="hover">
						Privacy Policy
					</Link>{' '}
					and{' '}
					<Link
						href="https://getmagnet.io/terms-and-conditions"
						target="_blank"
						color="inherit"
						underline="hover"
					>
						Terms and Conditions
					</Link>
					.
				</Typography>
			</Box>
		</Box>
	);
};

import React from 'react';
import { Box, Container, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Nav } from '@/templates/nav/nav';

type Props = {
	navItems?: boolean;
};

export const DashboardLayout = ({ navItems }: Props) => {
	return (
		<Box sx={{ bgcolor: 'grey.900', color: 'white', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
			<Container>
				<Nav navItems={navItems} />
			</Container>

			<Paper sx={{ borderRadius: '40px 40px 0 0', pt: 4, flex: 1 }} elevation={0}>
				<Container maxWidth="lg">
					<Outlet />
				</Container>
			</Paper>
		</Box>
	);
};

import React from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Icon } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { SimpleTable } from '@/molecules/simple-table';
import { BackofficeProcessingResultsTable } from '@/organisms/backoffice/backoffice-processing-results-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';

export const BackofficeOrganizationsMailboxDetail = () => {
	const { id, messageId: emailMessageId } = useParams<{ id: string; messageId: string }>();
	if (!id || !emailMessageId) throw new Error('No organization or message id');

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { data, isError, isLoading } = trpc.backoffice.organizations.mailbox.get.useQuery({
		id: emailMessageId,
	});
	const { data: attachments, isError: attachmentsIsError } =
		trpc.backoffice.organizations.mailbox.listAttachments.useQuery({
			id: emailMessageId,
		});

	const { data: invoices, isError: invoicesIsError } = trpc.backoffice.organizations.mailbox.listInvoices.useQuery({
		id: emailMessageId,
	});

	const { mutateAsync: download } = trpc.backoffice.organizations.mailbox.download.useMutation();
	const { mutateAsync: extract } = trpc.backoffice.organizations.mailbox.extract.useMutation();

	return (
		<>
			<Back to={links.backoffice.organizations.detail.mailbox.ROOT.replace(':id', id)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Message from mailbox of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError || attachmentsIsError) && (
				<Alert severity="error">There was an error</Alert>
			)}

			<Typography variant="h3">Overview</Typography>
			{data && (
				<SimpleTable
					rows={[
						['ID', data.id],
						['From', data.from],
						['Subject', data.subject],
						['Received At', <FormattedDatetime value={data.receivedAt} />],
						['Mailbox', data.mailboxId],
					]}
				/>
			)}

			<Typography variant="h3" sx={{ mt: 2 }}>
				Attachments
			</Typography>
			{attachments && (
				<SimpleTable
					rows={[
						['ID', 'Filename', 'Content Type', 'Content Available', 'Document AI result'],
						...attachments.map(({ id, filename, contentType, hasContent, hasDocumentAiResult }) => {
							return [
								id.slice(0, 24) + '...',
								filename,
								contentType,
								hasContent ? 'Yes' : 'No',
								hasDocumentAiResult ? 'Yes' : 'No',
							];
						}),
					]}
				/>
			)}

			<Typography variant="h3" sx={{ mt: 2 }}>
				Invoices
			</Typography>
			{invoices && (
				<SimpleTable
					rows={[
						['ID', 'Supplier Name', 'Total Amount', 'Invoice Date', 'Due Date'],
						...invoices.map(
							({ id: invoiceId, supplierName, totalAmount, invoiceDate, dueDate, currency }) => {
								return [
									<Link
										to={links.backoffice.organizations.detail.invoices.detail
											.replace(':id', id)
											.replace(':invoiceId', invoiceId)}
									>
										{invoiceId}
									</Link>,
									supplierName,
									<FormattedNumber
										value={centsToAmount(Number(totalAmount))}
										style="currency"
										currency={currency}
									/>,
									<FormattedDatetime value={invoiceDate} />,
									<FormattedDatetime value={dueDate} />,
								];
							},
						),
					]}
				/>
			)}

			<Typography variant="h3" sx={{ mt: 2 }}>
				Processing Results
			</Typography>
			<IconButton
				onClick={() => {
					download({ id: emailMessageId });
				}}
				sx={{ fontSize: 14 }}
			>
				<Icon name="Download" />
			</IconButton>
			<IconButton
				onClick={() => {
					extract({ id: emailMessageId });
				}}
				sx={{ fontSize: 14 }}
			>
				<Icon name="RefreshCw" />
			</IconButton>

			<BackofficeProcessingResultsTable emailMessageId={emailMessageId} />
		</>
	);
};

import React, { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

import { center } from '@/utils/sx';

type Props = {
	children: ReactNode;
	sx?: SxProps;
};

export const Circle = ({ children, sx = {} }: Props) => {
	return (
		<Box
			sx={{
				width: '3.5rem',
				height: '3.5rem',
				borderRadius: '50%',
				bgcolor: 'text.primary',
				color: 'white',
				...center,
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

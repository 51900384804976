import React from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { Link } from '@/atoms/link-component';
import { links } from '@/paths';

export const BackofficeHomepage = () => {
	return (
		<>
			<Typography variant="h2" sx={{ mb: 2 }}>
				Backoffice
			</Typography>
			<Stack direction="row" gap={0.5}>
				<Link to={links.backoffice.organizations.ROOT}>
					<Button size="small" variant="contained">
						Organizations
					</Button>
				</Link>
				<Link to={links.backoffice.users.ROOT}>
					<Button size="small" variant="contained">
						Users
					</Button>
				</Link>
				<Link to={links.backoffice.tools.ROOT}>
					<Button size="small" variant="contained">
						Tools
					</Button>
				</Link>
				<Link to={links.backoffice.helicopterLogs.ROOT}>
					<Button size="small" variant="contained">
						Helicopter logs
					</Button>
				</Link>
			</Stack>
		</>
	);
};

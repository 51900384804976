import React from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import { FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Icon } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['invoices']['list']['data'][number];

export const BackofficeOrganizationsInvoices = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const confirm = useConfirm();

	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { data, isError, isLoading } = trpc.backoffice.organizations.invoices.list.useQuery({
		organizationId: id,
		page,
		pageSize,
	});

	const columns: GridColDef<Item>[] = [
		{
			field: 'supplierName',
			headerName: 'Vendor',
			flex: 1,
			renderCell({ value, row: { id: invoiceId } }) {
				return (
					<Link
						to={links.backoffice.organizations.detail.invoices.detail
							.replace(':id', id)
							.replace(':invoiceId', invoiceId)}
					>
						{value}
					</Link>
				);
			},
		},
		{
			field: 'totalAmount',
			headerName: 'Amount',
			flex: 1,
			renderCell({ value, row: { currency } }) {
				return <FormattedNumber value={centsToAmount(value)} style="currency" currency={currency} />;
			},
		},
		{ field: 'invoiceDate', headerName: 'Date', flex: 1 },
		{ field: 'source', headerName: 'Source', flex: 1 },
	];

	const rowCount = usePaginationRowCount(data?.pagination.count);

	return (
		<>
			<Back to={links.backoffice.organizations.detail.ROOT.replace(':id', id)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Invoices of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<DataGrid
				rows={data?.data ?? []}
				loading={isLoading}
				columns={columns}
				density="compact"
				rowCount={rowCount}
				paginationModel={paginationModel}
				paginationMode="server"
				onPaginationModelChange={onPaginationModelChange}
			/>
		</>
	);
};

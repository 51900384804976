import React from 'react';
import { Alert } from '@mui/material';

import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { SimpleTable } from '@/molecules/simple-table';
import { trpc } from '@/trpc/client';

type Props =
	| {
			bankPaymentTransactionId: string;
	  }
	| {
			emailMessageId: string;
	  }
	| {
			invoiceId: string;
	  };

export const BackofficeProcessingResultsTable = (props: Props) => {
	const { data, isError } = trpc.backoffice.organizations.processingResults.list.useQuery(props);

	return (
		<>
			{isError && <Alert severity="error">There was an error</Alert>}

			{data && (
				<SimpleTable
					rows={[
						['Type', 'Result', 'Created At'],
						...data.map((result) => [
							result.type,
							JSON.stringify(result.result),
							<FormattedDatetime value={result.createdAt} />,
						]),
					]}
					sx={{ maxWidth: 900 }}
				/>
			)}
		</>
	);
};
